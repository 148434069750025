export default {
  bja_type: { description: "BJA Type" },
  desc: { description: "Description" },
  drug_schedule: { description: "Drug Schedule" },
  gcn: { description: "GCN" },
  gcn_seqno: { description: "GCN Sequence Number" },
  generic_name: { description: "Generic name" },
  label_name: { description: "Label name" },
  narx_active_ingredient_1: { description: "Active Ingredient 1" },
  narx_active_ingredient_2: { description: "Active Ingredient 2" },
  narx_active_ingredient_3: { description: "Active Ingredient 3" },
  narx_dose_1: { description: "Narx Dose 1" },
  narx_dose_2: { description: "Narx Dose 2" },
  narx_dose_3: { description: "Narx Dose 3" },
  narx_type_1: { description: "Narx Type 1" },
  narx_type_2: { description: "Narx Type 2" },
  narx_type_3: { description: "Narx Type 3" },
  narx_unit_eq_1: { description: "Narx Unit 1" },
  narx_unit_eq_2: { description: "Narx Unit 2" },
  narx_unit_eq_3: { description: "Narx Unit 3" },
  ndc: { description: "NDC" },
  route: { description: "Route" },
  source: { description: "Source" },
  strength: { description: "Strength" },
  therapeutic_class: { description: "Therapeutic Class" }
};
