import TokenService from "./token";
import { axios } from "../lib/axios";
export default class GcnService {
  async index(per_page, page, search_fields = null) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let response = await axios(token).get("/gcns", {
      params: { per_page, page, ...search_fields }
    });
    return response;
  }

  async get(id) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let response = await axios(token).get(`/gcns/${id}/edit`);
    return response.data;
  }

  async save(drug) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let response = await axios(token).patch(`/gcns/${drug.gcn_seqno}`, drug);
    return response.data;
  }

  async approve(id) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let response = await axios(token).patch(`/gcns/${id}/approve`);
    return response.data;
  }
}
