import TokenService from "./token";
import { axios } from "../lib/axios";
export default class DrugService {
  async index(per_page, page, search_fields = null) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let response = await axios(token).get("/drugs", {
      params: { per_page, page, ...search_fields }
    });
    return response;
  }

  async get(id) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    let drugResponse = await axios(token).get(`/drugs/${id}`);
    return drugResponse.data;
  }

  async save(drug) {
    let tokenService = new TokenService();
    let token = await tokenService.getToken();
    await axios(token).post(`/drugs`, drug);
  }
}
